export default function LandingPage() {
  return (
    <div className="font-inter text-gray-900">
      {/* Hero */}
      <section className="bg-[url('https://images.unsplash.com/photo-1600185365483-26d7a4cc7510?auto=format&fit=crop&w=1600&q=80')] bg-cover bg-center min-h-screen flex items-center justify-center text-white px-6">
        <div className="bg-black/50 p-10 rounded-xl text-center max-w-2xl">
          <h1 className="font-serif text-5xl mb-4">Luxury, Reimagined</h1>
          <p className="mb-6 text-lg">
            ONDE is Australia’s first luxury handbag subscription club. Access a curated rotation of designer pieces from Chanel, Dior, Gucci, and more.
          </p>
          <a
            href="https://onde.beehiiv.com/subscribe"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-white text-black px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transition-all"
          >
            Join the Waitlist
          </a>
        </div>
      </section>

      {/* Our Collection */}
      <section className="py-20 px-6 text-center bg-white">
        <h2 className="font-serif text-3xl mb-4">Our Collection</h2>
        <p className="text-gray-600 mb-10 max-w-xl mx-auto">
          From timeless classics to seasonal exclusives, ONDE’s collection offers a new way to experience luxury.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 max-w-5xl mx-auto">
          <img src="https://images.unsplash.com/photo-1587300003388-59208cc962cb?auto=format&fit=crop&w=600&q=80" className="rounded-xl shadow-md" />
          <img src="https://images.unsplash.com/photo-1593032465171-ae2f7f4ca9f9?auto=format&fit=crop&w=600&q=80" className="rounded-xl shadow-md" />
          <img src="https://images.unsplash.com/photo-1618354691218-48c5d230b8dd?auto=format&fit=crop&w=600&q=80" className="rounded-xl shadow-md" />
        </div>
      </section>

      {/* How It Works */}
      <section className="bg-gray-50 py-20 px-6 text-center">
        <h2 className="font-serif text-3xl mb-4">How It Works</h2>
        <div className="grid gap-10 max-w-4xl mx-auto mt-10 text-left sm:grid-cols-3">
          <div>
            <h3 className="text-xl font-semibold mb-2">1. Join</h3>
            <p>Become a member and unlock access to our exclusive rotating collection.</p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">2. Select</h3>
            <p>Choose a handbag from our curated designer collection and we’ll ship it to your door.</p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">3. Swap</h3>
            <p>Swap monthly or keep your favorite longer. Return with ease and choose again.</p>
          </div>
        </div>
      </section>

      {/* Join CTA */}
      <section className="py-20 px-6 bg-black text-white text-center">
        <h2 className="font-serif text-3xl mb-4">Membership is Limited</h2>
        <p className="mb-6 max-w-xl mx-auto">
          Founding members receive exclusive perks and early access to our most sought-after styles.
        </p>
        <a
          href="https://onde.beehiiv.com/subscribe"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-white text-black px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transition-all"
        >
          Apply to Join
        </a>
      </section>
    </div>
  );
}
